import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import ServiceCard from "../../components/service-card";
import Faq from "../../components/faq";
import Reviews from "../../components/reviews";
import CtaBanner from "../../components/cta-banner";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SVGZoomDiscovery from "../../components/SVG/zoom-discovery-svg";
import SVGIdentify from "../../components/SVG/identify-svg";
import SVGContentCreated from "../../components/SVG/content-created-svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import SVGCopywriting from "../../components/SVG/copywriting-svg";
import ServiceHeroVideo from "../../components/service-hero-video";

const GhostwritingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Info-Panel-Image-Tall" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			socialImg: wpMediaItem(title: { eq: "Transform Your Career" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			websiteCopyImg: wpMediaItem(title: { eq: "Translation-Services" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blogWritingImg: wpMediaItem(title: { eq: "Unleash Your Message" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			advertisingImg: wpMediaItem(title: { eq: "Expand Your Reach" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			digitalImg: wpMediaItem(title: { eq: "Build Trust" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blog2Img: wpMediaItem(title: { eq: "Leave A Lasting Legacy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ayrImg: wpMediaItem(title: { eq: "Why-Choose-ARC" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const faqs = [
		{
			question:
				"Why should I choose ARC Writing and Translation Services to write my copy?",
			answer:
				"Because having your copy written by a professional writer who knows how to strike the right tone for your business will make your business more successful by creating the right impression with your existing and potential customers, leaving you free to focus on what you do best: running your business",
		},
		{
			question:
				"How do I know ARC Writing and Translation Services will write the best copy for me?",
			answer:
				"Once you have approached ARC Writing and Translation Services to provide you with a quote a meeting will be set up, face-to-face, online – via Zoom or an equivalent platform – or over the phone, to discuss your requirements and get a deeper understanding of your business.",
		},
		{
			question:
				"How will ARC Writing and Translations Services know how my industry works and what copy works best for me business?",
			answer:
				"Once an initial meeting has been set up, ARC Writing and Translation Services will undertake the relevant industry-specific research to identify the correct tone and terminology for your business and find out who your target customers are so that the copy delivered is tailored for the right audience.",
		},
		{
			question: "How much will it cost?",
			answer:
				"All copywriting projects involve different amounts of research and writing time, so it’s not a case of one size fits all. Once you get in touch and outline your needs, a price structure can be determined.",
		},

		{
			question: "How long will it take to deliver the copy?",
			answer:
				"Delivery will depend on the scope of the project and will be discussed upon initial contact. Urgent needs can be accommodated and rush rates applied. The version delivered is never the first draft, so time is needed to write at least three drafts to arrive at the perfect final draft.",
		},

		{
			question:
				"Is it possible to request revisions of the work and, if so, how many?",
			answer:
				"Yes, absolutely. Up to 2 revisions will be provided as standard. Any further changes may be subject to an extra fee.",
		},
	];
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const socialImg = data.socialImg?.localFile.childImageSharp.gatsbyImageData;
	const websiteCopyImg =
		data.websiteCopyImg?.localFile.childImageSharp.gatsbyImageData;

	const blogWritingImg =
		data.blogWritingImg?.localFile.childImageSharp.gatsbyImageData;

	const advertisingImg =
		data.advertisingImg?.localFile.childImageSharp.gatsbyImageData;

	const digitalImg = data.digitalImg?.localFile.childImageSharp.gatsbyImageData;
	const blog2Img = data.blog2Img?.localFile.childImageSharp.gatsbyImageData;
	const ayrImg = data.ayrImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Ghostwriting",
				item: {
					url: `${siteUrl}/services/ghostwriting`,
					id: `${siteUrl}/services/ghostwriting`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Ghostwriting"
				description="Unlock your brand’s potential with an experienced non-fiction ghostwriter and transform yourself from a business leader to a thought leader"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/ghostwriting`,
					title: "Ghostwriting",
					description:
						"Unlock your brand’s potential with an experienced non-fiction ghostwriter and transform yourself from a business leader to a thought leader",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/copywriting-services-scaled-1.jpg`,
							width: 1621,
							height: 2560,
							alt: "Ghostwriting services in London",
						},
					],
				}}
			/>
			<section className="pt-5 pb-5 pb-lg-0 pt-lg-6">
				<Container>
					<Row>
						<Col className="text-center">
							<h1 className="underline fs-1">Ghostwriting</h1>
							<p className="fatface mt-4">
								Unlock your brand’s potential with an experienced non-fiction
								ghostwriter and transform yourself from a business leader to a
								thought leader
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<ServiceHeroVideo
				secondButton={false}
				imgWidth="15rem"
				title={false}
				video="https://www.youtube.com/embed/eFyTW_KAGKI?si=JeEYYNd69TrTsTBY"
				videoAlt="Why hire a ghostwriter?"
				text={
					<>
						<p className=" fs-5 fw-bold">
							Are you a coach or a professional for whom personal branding is
							essential?
						</p>
						<p className=" fs-5 fw-bold">
							Do you long to see your non-fiction book come to life, but find
							the idea of the writing process overwhelming?
						</p>
						<p>
							Let my professional ghostwriting service guide you in transforming
							your stories into a captivating manuscript that establishes you as
							a thought leader and opens doors to unbelievable speaking and
							media opportunities.
						</p>
					</>
				}
			/>
			<section className="bg-dark-background d-lg-none">
				<Container>
					<Row className="justify-content-center">
						<Col className="py-5" lg={6}>
							{" "}
							<div className="iframe-container-new ">
								<iframe
									loading="lazy"
									className="responsive-iframe"
									src="https://www.youtube.com/embed/eFyTW_KAGKI?si=JeEYYNd69TrTsTBY"
									title="Why hire a ghostwriter?"
									frameborder="0"
									allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<Container className="text-center pt-lg-0 pt-5">
				<h2 className="mb-5 underline fs-1 ">Ghostwriting benefits</h2>
				<Row>
					<ServiceCard
						image={websiteCopyImg}
						imgAlt="The power of authorship"
						title="The power of authorship"
						text="A book instantly positions you as an authority in your field and adds credibility and trustworthiness to your name. Your journey, knowledge and unique insights become valuable assets that can inspire and impact countless readers worldwide."
					/>
					<ServiceCard
						image={blogWritingImg}
						imgAlt={data.blogWritingImg.altText}
						title="Unleash your message"
						text="Your book will provide a platform for you to share your message with the world. Whether you're a business leader, coach or wellness expert, a book allows you to delve deeper into your expertise, offering practical advice and transformative ideas. It's a chance to connect with your audience on a profound level and leave a lasting impression."
					/>
					<ServiceCard
						image={advertisingImg}
						imgAlt={data.advertisingImg.altText}
						title="Expand your reach"
						text="Imagine the doors that will open when you become a published author. Your book becomes a calling card that can attract new clients, speaking engagements, media opportunities and partnerships. It's the spark that ignites conversations and expands your reach far beyond your current network."
					/>
					<ServiceCard
						image={digitalImg}
						imgAlt={data.digitalImg.altText}
						title="Build trust and authority"
						text="In the world of personal branding, trust is everything. A well-written book showcases your commitment to your field and your dedication to helping others. It positions you as a thought leader and an industry expert, making it easier for potential clients to choose you over competitors."
					/>
					<ServiceCard
						image={blog2Img}
						imgAlt={data.blog2Img.altText}
						title="Leave a lasting legacy"
						text="A book is a legacy you leave for generations to come. It's a tangible representation of your ideas, experiences and the impact you've made on countless lives. Your words can continue to inspire, educate, and empower long after you've shared them with the world."
					/>
					<ServiceCard
						image={socialImg}
						imgAlt={data.socialImg.altText}
						title="Transform your career"
						text="Having a book can lead to incredible opportunities. From speaking engagements at prestigious events to media features in major publications, doors will open that you never thought possible. Your book can propel your career to new heights, creating a ripple effect of success in all aspects of your professional journey."
					/>
				</Row>
			</Container>
			<section>
				{" "}
				<div Tag="section" className=" position-relative   ">
					<div
						style={{ zIndex: "-3" }}
						className="bg-light-background d-lg-none h-100 w-100 position-absolute"
					></div>
					<Container className="pt-lg-4 ">
						<Row className="  px-lg-3 pt-lg-5 justify-content-center">
							<Col lg={9}>
								<div className="position-relative ">
									<div
										style={{ zIndex: "-3" }}
										className="bg-light-background d-none d-md-block h-100 w-100 position-absolute"
									></div>
									<div className="position-absolute top-10 d-none d-lg-block start-0 translate-middle-x">
										<GatsbyImage
											style={{ maxWidth: "20rem", height: "15rem" }}
											image={ayrImg}
											alt="Are you ready to begin your journey as an author?"
										/>
									</div>

									<Col
										lg={{ span: 9, offset: 3 }}
										xl={{ span: 8, offset: 3 }}
										className="px-xl-3 ps-lg-4 pe-lg-4 pt-4 pb-lg-6 pb-5 "
									>
										<h2 className="pt-3 mb-2 pb-1 fs-4 d-none d-xl-inline-block underline">
											Are you ready to begin your journey as an author?
										</h2>
										<h2 className="pt-3 mb-2 pb-1 d-xl-none fs-4 ">
											Are you ready to begin your journey as an author?
										</h2>

										<p className="mb-lg-0 fw-light">
											As a ghostwriter, I'm here to help you turn your ideas
											into a compelling book that aligns perfectly with your
											brand and resonates with your audience. Let's collaborate
											to create a powerful narrative that leaves a lasting
											impact and elevates your personal brand.
										</p>
										<Row>
											<Col className="mt-lg-4 mt-md-0 pb-md-6" md={{ span: 7 }}>
												<Button
													className="cta-btn mt-md-4 mt-3 py-2 w-100"
													variant="secondary"
													as={Link}
													to="/contact"
												>
													Contact
												</Button>
											</Col>
										</Row>
									</Col>
								</div>
							</Col>
						</Row>
						<Row className="justify-content-center pb-5 pb-md-0 position-relative">
							<Col lg={8}>
								<div
									style={{ boxShadow: "0px 3px 13px 3px rgba(0, 0, 0, 0.15)" }}
									className="bg-white py-3 px-md-5 top-lifted px-3 position-relative"
								>
									<Row>
										<Col>
											<h2 className=" mb-md-5 mb-4 fs-4 text-center">
												Why hire me to ghostwrite your book?
											</h2>
										</Col>
									</Row>
									<Row className="g-4">
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Elevate your writing
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												I can add finesse and polish to your manuscript,
												ensuring it captivates readers with its professional
												quality.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Expert storytelling
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												I have a unique talent for crafting compelling
												narratives that keep readers enthralled.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Save time and energy
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												Focus on what you do best while I handle the writing of
												your book. By hiring me as your ghostwriter you retain
												the freedom to follow your passions and meet your
												commitments.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Confidentiality assured
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												Rest assured that your ideas and personal information
												will remain confidential. I work discreetly, allowing
												you to claim full authorship of your book.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Overcome obstacles
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												Whether you’re suffering from writer's block or the
												challenge of shaping your ideas, I can provide the
												support and guidance to transform your vision into a
												coherent and engaging story.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												A collaborative journey
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p>
												Your voice matters. Together, we'll work closely to
												understand your vision and goals, ensuring the final
												product remains true to your unique style and is told in
												your voice.
											</p>
										</Col>
										<Col md={6}>
											<h3 className="normal-font fs-6 fw-bold mt-3">
												Fulfil your dream
											</h3>
											<hr
												className="bg-primary"
												style={{ height: "5px", width: "150px", opacity: "1" }}
											/>
											<p className="pb-4">
												Watch your dream come true as your book takes shape in
												my skilled hands and leaves a lasting legacy for future
												generations.
											</p>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</section>
			<section className="py-5 pt-lg-0 pb-lg-7">
				<Container>
					<Row>
						<Col>
							<div className="text-center">
								<h2 className="mb-5 underline d-none d-md-inline-block fs-1 text-center">
									What it’s like working with me
								</h2>
								<h2 className="mb-5 d-md-none fs-1 text-center">
									What it’s like working with me
								</h2>
							</div>
							<p>
								You may be wondering what it’s like working with ghostwriter,
								which is why I have mapped out the journey we will embark on
								together from start to finish so you know what to expect along
								the way.
							</p>
							<h3 className=" mb-4 fs-4 fatface">Initial consultation</h3>
							<p>
								In this session, we will discuss your goals, vision and book
								concept to unveil your story. We will look back at past
								successes and failures, key life moments and unique experiences
								and what you learnt as a result. We will do this by answering
								the following questions.
							</p>
							<ul
								style={{ fontWeight: "600" }}
								className="copy-list ps-0  py-4"
							>
								<li className="fw-bold">
									What is the book about and why do you want to write this book?
								</li>
								<li className="fw-bold">What inspired you to write a book?</li>
								<li className="fw-bold">
									What past events made you the person you are now?
								</li>
								<li className="fw-bold">
									Imagine your life as a movie, what would it look like?
								</li>
								<li className="fw-bold">
									Which word best sums up your book? E.g. “hope, “teach”,
									“change”, “inspire”.
								</li>
								<li className="fw-bold">Who is your target audience?</li>
							</ul>
							<h3 className=" mb-4 fs-4 fatface">Project proposal</h3>
							<p>
								Based on the initial consultation, I will create a detailed
								project proposal outlining the scope, timeline, deliverables and
								cost of my ghostwriting services.
							</p>
							<p>
								In my proposal, I will indicate the value I can provide, such as
								understanding and retelling your unique story, my expertise as a
								writer and storyteller and how to position you as a thought
								leader or someone with a valuable story and message to share and
								inspire others.
							</p>
							<h3 className=" mb-4 fs-4 fatface">Agreement and payment</h3>
							<p>
								Once you accept my proposal, I will prepare a written agreement
								that includes terms, deliverables, services, confidentiality and
								payment details.
							</p>
							<p>
								We can then discuss and finalise the payment terms, e.g. upfront
								fees, instalments or payment by milestones.
							</p>
							<p>Most non-fiction books are between 30,000 and 40,000 words.</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="position-relative  pb-5 pb-md-0 pb-lg-5 ">
				<div
					style={{ zIndex: "-3" }}
					className="position-absolute w-100 h-100 bg-light-background  "
				></div>
				<Container className="py-5">
					<Row>
						<Col className="text-center">
							<h2 className="underline mb-5 fs-1">My Approach</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col className="px-4 text-center" lg={5}>
							<SVGContentCreated className="mw-20 mb-3 h-25" />

							<h3 className="normal-font  fs-4 fw-bold">
								Research and interviews
							</h3>

							<p className="text-start">
								I will begin by conducting research on your industry, background
								and target audience.
							</p>
							<p className="text-start">
								I will schedule interviews with you or ask you to send me your
								story via voice notes on your phone to gather additional
								information, anecdotes and personal experiences to enrich and
								populate the content of the book.
							</p>
						</Col>
						<Col className="px-4 text-center pt-4 pt-md-0" lg={5}>
							<SVGIdentify className="mw-20 mb-3 h-25" />
							<h3 className="normal-font  fs-4 fw-bold">
								Outline and content development
							</h3>

							<p className="text-start">
								I will develop a comprehensive book outline that captures the
								main themes, chapters, and key messages to be conveyed.
							</p>
							<p className="text-start">
								I will start crafting the content based on the outline, using a
								writing style that aligns with your voice and tone.
							</p>
						</Col>
						<Col className="px-4 text-center " lg={5}>
							<SVGZoomDiscovery className="mw-20 mb-3 h-25" />
							<h3 className="normal-font  fs-4 fw-bold">
								Iterative writing and feedback
							</h3>

							<p className="text-start">
								I will share the initial draft of each chapter/section
								(depending on the frequency we have agreed) with you for you to
								review and provide feedback.
							</p>
							<p className="text-start">
								I will incorporate your suggestions, edits and revisions to
								refine and strengthen the manuscript.
							</p>
							<p className="text-start">
								We will maintain open communication and I will provide regular
								updates to keep you informed throughout the writing process.
							</p>
						</Col>
						<Col className="px-4 text-center pt-4 pt-md-0 " lg={5}>
							<SVGCopywriting className="mw-20 mb-3 h-25" />
							<h3 className="normal-font  fs-4 fw-bold">
								Editing and proofreading
							</h3>

							<p className="text-start">
								Once the full manuscript is complete, I will commission a copy
								editor to perform a thorough editing and proofreading process to
								ensure clarity, coherence and consistency. I will then work
								closely with the copy editor to ensure the overall quality and
								optimum readability of the book.
							</p>
							<p className="text-start">
								Once the manuscript has been signed off I will connect you with
								a publisher for formatting, typesetting, design, publishing,
								distribution and marketing.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline={<>Click here to book a free 30-minute discovery call</>}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
			<div className="py-5 py-lg-7">
				<Reviews />
			</div>
		</Layout>
	);
};

export default GhostwritingPage;
