import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const ServiceHeroVideo = ({
	title,
	text,
	video,
	videoAlt,
	subtitle,
	heading,
	imgWidth,
	secondButton,
	secondButtonLink,
	secondButtonText,
}) => {
	return (
		<>
			<div Tag="section" className=" position-relative  mb-lg-7  ">
				<div
					style={{ zIndex: "-3" }}
					className="bg-light-background d-lg-none h-100 w-100 position-absolute"
				></div>
				<Container className="pt-lg-4 ">
					<Row className="  px-md-3 pt-lg-5 justify-content-center">
						<Col md={11} lg={8}>
							<div className="position-relative ">
								<div
									style={{ zIndex: "-3" }}
									className="bg-light-background d-none d-md-block h-100 w-100 position-absolute"
								></div>
								<div className="position-absolute top-50 w-50 d-none d-lg-block start-0 translate-middle">
									<div className="iframe-container-new ">
										<iframe
											loading="eager"
											className="responsive-iframe"
											src={video}
											title={videoAlt}
											frameborder="0"
											allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>

								<Col
									lg={{ span: 9, offset: 3 }}
									xl={{ span: 8, offset: 3 }}
									className="px-xl-5 ps-lg-4 pe-lg-4 pt-4 pb-6 ps-md-3"
								>
									{title !== false && (
										<h1
											className="pt-3 mb-1 underline"
											id="services-hero-title"
										>
											{title}
										</h1>
									)}
									<p className=" fs-5 mb-1 fw-light">{subtitle}</p>
									<p className=" fs-5 fw-bold">{heading}</p>
									<p className="mb-lg-0 fw-light">{text}</p>
									<Row>
										<Col
											className="mt-4 mt-md-0"
											md={{ span: `${secondButton ? 5 : 7}` }}
										>
											<Button
												className="cta-btn py-2 w-100"
												variant="secondary"
												as={Link}
												to="/contact"
											>
												Contact
											</Button>
										</Col>
										{secondButton === true && (
											<Col className="mt-2 mt-md-0" md={7}>
												<Button
													className="cta-btn text-white  py-2  w-100"
													variant="primary"
													target="_blank"
													rel="noreferrer"
													href={secondButtonLink}
												>
													{secondButtonText}
												</Button>
											</Col>
										)}
									</Row>
								</Col>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default ServiceHeroVideo;
