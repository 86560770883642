import * as React from "react";

const SVGCopywriting = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={108.432}
		height={111.257}
		viewBox="0 0 108.432 111.257"
		{...props}
	>
		<g id="Layer_2" data-name="Layer 2" transform="translate(0.001)">
			<g id="Layer_1" data-name="Layer 1" transform="translate(1.499 1.5)">
				<path
					id="Path_46"
					data-name="Path 46"
					d="M25.843,33.267H48.772c2.864,0,2.873-4.447,0-4.447H25.843C22.979,28.82,22.979,33.267,25.843,33.267Z"
					transform="translate(-3.954 -4.522)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_47"
					data-name="Path 47"
					d="M25.843,43.867H48.772c2.864,0,2.873-4.447,0-4.447H25.843C22.979,39.42,22.979,43.867,25.843,43.867Z"
					transform="translate(-3.954 -5.694)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_48"
					data-name="Path 48"
					d="M25.843,54.447H63.1c2.855,0,2.864-4.447,0-4.447H25.843C22.979,50,22.979,54.447,25.843,54.447Z"
					transform="translate(-3.954 -6.864)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_49"
					data-name="Path 49"
					d="M26.133,65.057H63.4c2.864,0,2.864-4.447,0-4.447H26.133C23.278,60.61,23.269,65.057,26.133,65.057Z"
					transform="translate(-3.987 -8.038)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_50"
					data-name="Path 50"
					d="M26.133,75.647H50.627c2.864,0,2.873-4.447,0-4.447H26.133C23.278,71.2,23.269,75.647,26.133,75.647Z"
					transform="translate(-3.987 -9.209)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_51"
					data-name="Path 51"
					d="M26.133,86.247H50.627c2.864,0,2.873-4.447,0-4.447H26.133C23.278,81.8,23.269,86.247,26.133,86.247Z"
					transform="translate(-3.987 -10.381)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_52"
					data-name="Path 52"
					d="M26.133,96.847H50.627c2.864,0,2.873-4.447,0-4.447H26.133C23.278,92.4,23.269,96.847,26.133,96.847Z"
					transform="translate(-3.987 -11.554)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<path
					id="Path_53"
					data-name="Path 53"
					d="M78.475,37.2H60.82V19.68"
					transform="translate(-8.06 -3.511)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_54"
					data-name="Path 54"
					d="M81.04,39.74V65.719"
					transform="translate(-10.297 -5.729)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_55"
					data-name="Path 55"
					d="M73.968,72.715V97.653H17.1V19.68H55.984"
					transform="translate(-3.225 -3.511)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_56"
					data-name="Path 56"
					d="M59.9,19.3l.565.552,19,18.73.361.35"
					transform="translate(-8.479 -3.596)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={4}
				/>
				<path
					id="Path_57"
					data-name="Path 57"
					d="M3.575,0H53.444V6.493H2.944A2.944,2.944,0,0,1,0,3.558H0A3.558,3.558,0,0,1,3.558,0Z"
					transform="translate(104.81 35.096) rotate(135)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<line
					id="Line_28"
					data-name="Line 28"
					x2={4.607}
					y2={4.607}
					transform="translate(95.068 35.665)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<path
					id="Path_58"
					data-name="Path 58"
					d="M87.1,46.566V7.157A5.568,5.568,0,0,0,81.626,1.5H6.979A5.577,5.577,0,0,0,1.5,7.157V104.1a5.577,5.577,0,0,0,5.479,5.657H81.626A5.568,5.568,0,0,0,87.1,104.1V56.491"
					transform="translate(-1.499 -1.5)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<path
					id="Path_59"
					data-name="Path 59"
					d="M62,91.532l9.783-5.176,2.739-2.739L69.951,79l-2.926,2.926Z"
					transform="translate(-8.191 -10.072)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<path
					id="Path_60"
					data-name="Path 60"
					d="M87.1,7.157V9.176H1.5V7.157A5.577,5.577,0,0,1,6.979,1.5H81.626A5.568,5.568,0,0,1,87.1,7.157Z"
					transform="translate(-1.499 -1.5)"
					fill="none"
					stroke="#c157a1"
					strokeMiterlimit={10}
					strokeWidth={3}
				/>
				<ellipse
					id="Ellipse_18"
					data-name="Ellipse 18"
					cx={1.299}
					cy={1.343}
					rx={1.299}
					ry={1.343}
					transform="translate(5.346 2.561)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<ellipse
					id="Ellipse_19"
					data-name="Ellipse 19"
					cx={1.299}
					cy={1.343}
					rx={1.299}
					ry={1.343}
					transform="translate(10.166 2.561)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
				<ellipse
					id="Ellipse_20"
					data-name="Ellipse 20"
					cx={1.299}
					cy={1.343}
					rx={1.299}
					ry={1.343}
					transform="translate(15.254 2.561)"
					fill="#c157a1"
					stroke="#c157a1"
					strokeWidth={1}
				/>
			</g>
		</g>
	</svg>
);

export default SVGCopywriting;
